import {Routes, Route, useNavigate, Navigate} from 'react-router-dom'

import MonitorScreen from '../screens/MonitorScreen'
import VehicleScreen from '../screens/VehicleScreen'
import AccountScreen from '../screens/AccountScreen'
import SignOutScreen from '../screens/SignOutScreen'
import AddVehicleScreen from '../screens/AddVehicleScreen'
import useQuery from '../hooks/useQuery'
import { useEffect } from 'react'

const LandingPage = () => {

    const navigate = useNavigate()
    const query = useQuery()

    useEffect(() => {
        const returnUrl = query.get('returnUrl')
        if (returnUrl)
            navigate(returnUrl)
        else   
            navigate('/monitor')
    }, [query])
}

const AuthenticatedRoutes = () => {

    return (
        <Routes>
            <Route path="monitor" element={<MonitorScreen />} />
            <Route path="vehicle/*">
                <Route path="add/*">
                    <Route path=":deviceId" element={<AddVehicleScreen />} />
                    <Route path="" element={<AddVehicleScreen />} />
                </Route>
                <Route path=":vehicleId" element={<VehicleScreen />} />
            </Route>
            <Route path="account" element={<AccountScreen />} />
            <Route path="signOut" element={<SignOutScreen />} />
            <Route path="signIn/*" element={<LandingPage />} />
            <Route path="signUp/*" element={<LandingPage />} />
            <Route path="/*" element={<Navigate to="/monitor" />} />
        </Routes>
    )
}

export default AuthenticatedRoutes