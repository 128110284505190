import { useEffect, useState } from "react"
import { useMap, MapContainer, Marker, CircleMarker, TileLayer, Tooltip, Polyline, Circle } from "react-leaflet"
import { Box, CircularProgress, Paper, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material"
import moment from "moment";
import L from 'leaflet';

import { useAccessToken, useVehicles } from "../../../context/AuthContext"
import { getLatestVehicleRecord, getRecords } from "../../../api/vehicle"
import { BatteryStd, DirectionsCar, Key, KeyOutlined, Speed } from "@mui/icons-material";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const makeStyles = theme => ({
    monitor: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    map: {
        flex: 1
    },
    properties: {
        display: 'flex',
        flexWrap: 'wrap',
        p: 1
    },
    property: {
        flexBasis: '50%',
        p: 1
    }
})

const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
        if (lat == 0 && lng == 0)
            return 

        map.setView([lat, lng]);
    }, [lat, lng]);
     return null;
   }

const modes = [
    () => new Date().getTime() - 300000,
    () => new Date().getTime() - 3.6e+6,
    () => new Date().getTime() - 2.16e+7,
    () => new Date().getTime() - 4.32e+7,
    () => new Date().getTime() - 8.64e+7,
    () => new Date().getTime() - 6.048e+8,
]

const getIgnition = record => {
    const ignition = record.properties.find(property => property.Id == 239)
    if (!ignition)
        return 'Off'

    return ignition.Value == 1 ? 'On' : 'Off'
}
const getMovement = record => {
    const movement = record.properties.find(property => property.Id == 240)
    if (!movement)
        return 'Stationary'

    return movement.Value == 1 ? 'Mobile' : 'Stationary'
}
const getSpeed = record => !!record ? Math.floor(record.speed * 0.621371) : 0
const getBatteryVoltage = record => {
    const batteryVoltage = record.properties.find(property => property.Id == 66)
    if (!batteryVoltage)
        return '0V'

    return batteryVoltage.Value
}

const Map = () => {

    const [accessToken] = useAccessToken()
    const [vehicles] = useVehicles()
    const theme = useTheme()
    const styles = makeStyles(theme)

    const [mode, setMode] = useState(0)
    const [records, setRecords] = useState([])
    
    useEffect(() => {
        if (vehicles.length == 0)   
            return

        let records = []
        let since = modes[mode]() || 0
        let pending = true
        let terminated = false
            
        fetchRecords(vehicles[0].deviceId, since)
            .then(_records => {
                if (terminated)
                    return
                if (!!_records[0])
                    since = _records[0].timestamp

                records = [..._records, ...records]
                setRecords(records)
            })
            .catch(err => console.log(err))
            .finally(() => pending = false)        

        const fetchInterval = setInterval(() => {
            if (pending)
                return
            pending = true

            fetchRecords(vehicles[0].deviceId, since)
                .then(_records => {
                    if (terminated)
                        return
                    if (!!_records[0])
                        since = _records[0].timestamp

                    records = [..._records, ...records]
                    setRecords(records)
                })
                .catch(err => console.log(err))
                .finally(() => pending = false)
        }, 1000)

        return () => {
            terminated = true
            clearInterval(fetchInterval)
        }
    }, [vehicles, mode])

    const fetchRecords = (deviceId, since) => {        
        return new Promise((resolve, reject) => {
            getRecords(accessToken, deviceId, since, undefined)
                .then(({data: {records}}) => {
                    resolve(records)
                })
                .catch(err => reject(err))
        })
    }

    return (
        <Paper sx={styles.monitor}>
            <Tabs value={mode} onChange={((undefined, mode) => setMode(mode))}>
                <Tab value={0} label="Live" />
                <Tab value={1} label="1H" />
                <Tab value={2} label="6H" />
                <Tab value={3} label="1D" />
                <Tab value={4} label="1W" />
            </Tabs>
            <MapContainer center={records[0] ? [records[0].latitude, records[0].logitude] : [0, 0]} zoom={16} scrollWheelZoom={false} style={styles.map}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />  
                {records.map((record, i) => 
                    i == 0 && (
                        <Marker key={record._id}  position={[record.latitude, record.logitude]}>
                            <Tooltip permanent={true} direction="bottom" opacity={1} offset={[-15.5, 28]}>
                                {vehicles?.[0]?.name}
                            </Tooltip>
                        </Marker>   
                    ) 
                )}
                {records.map((record, i) => 
                    i + 1 < records.length && (
                        <Polyline 
                            pathOptions={{color: record.properties.find(property => property.Id == 253) ? theme.palette.error.main : theme.palette.success.main}}
                            positions={[[record.latitude, record.logitude], [records[ i + 1].latitude, records[i + 1].logitude]]}
                        />
                    )
                )}
                <RecenterAutomatically lat={records[0] ? records[0].latitude : 0} lng={records[0] ? records[0].logitude : 0} />
            </MapContainer>

            <Box component="div" sx={styles.properties}>
                <Stack direction="column" rowGap={1} sx={{flexBasis: '100%', p: 1}}>
                    <Typography variant="body1" fontWeight="bold">
                        {vehicles[0].name}
                    </Typography>
                    <Typography variant="body2" color="gray">
                        {vehicles[0]?.make} &bull; {vehicles[0]?.variant} &bull; {vehicles[0]?.year}
                    </Typography>
                </Stack>
                <Stack direction="row" columnGap={2} alignItems="center" sx={styles.property}>
                    <Key />
                    <Typography variant="body2">
                        {records[0] && getIgnition(records[0])}
                    </Typography>
                </Stack>
                <Stack direction="row" columnGap={2} alignItems="center" sx={styles.property}>
                    <DirectionsCar />
                    <Typography variant="body2">
                        {records[0] && getMovement(records[0])}
                    </Typography>
                </Stack>
                <Stack direction="row" columnGap={2} alignItems="center" sx={styles.property}>
                    <Speed />
                    <Typography variant="body2">
                        {records[0] && getSpeed(records[0])} mph
                    </Typography>
                </Stack>
                <Stack direction="row" columnGap={2} alignItems="center" sx={styles.property}>
                    <BatteryStd />
                    <Typography variant="body2">
                        {records[0] && getBatteryVoltage(records[0])}
                    </Typography>
                </Stack>
                <Typography variant="caption" sx={{p: 1}}>
                    {moment.unix((records[0]?.timestamp || 0) / 1000).format('DD/MM/YYY hh:mm:ss A')}
                </Typography>
            </Box>
        </Paper>
    )
}

export default Map

/*
                <Polyline 
                    pathOptions={{color: theme.palette.primary.main}}
                    positions={records.reduce((value, record) => {
                        value.push([record.latitude, record.logitude])
                        return value
                    }, [])}
                />
*/