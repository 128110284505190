import {Grid, Stack, Typography, TextField} from '@mui/material'
import moment from 'moment'

const VehicleData = ({editable, value: {deviceId, name, vrm, vin, make, variant, year, color, registrationDate, bodyStyle, fuelType, engineCapacity, transmission}, onChange}) => {

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Device ID
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        <TextField
                            variant="standard"
                            size="small"
                            fontWeight="bold"
                            InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                            value={deviceId}
                            onChange={(e) => onChange(_value => ({..._value, deviceId: e.target.value}))}
                        />
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Name
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        <TextField
                            variant="standard"
                            size="small"
                            fontWeight="bold"
                            InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                            value={name}
                            onChange={(e) => onChange(_value => ({..._value, name: e.target.value}))}
                        />
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        VRM
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        <TextField
                            variant="standard"
                            size="small"
                            fontWeight="bold"
                            InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                            value={vrm}
                            onChange={(e) => onChange(_value => ({..._value, vrm: e.target.value}))}
                        />
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        VIN
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        <TextField
                            variant="standard"
                            size="small"
                            fontWeight="bold"
                            InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                            value={vrm}
                            onChange={(e) => onChange(_value => ({..._value, vrm: e.target.value}))}
                        />
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Make
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={make}
                        onChange={(e) => onChange(_value => ({..._value, make: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Model
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={variant}
                        onChange={(e) => onChange(_value => ({..._value, variant: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Year
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={year}
                        onChange={(e) => onChange(_value => ({..._value, year: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Date of registration
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={!!registrationDate ? moment(registrationDate).format('DD/MM/YYYY') : ''}
                        onChange={(e) => onChange(_value => ({..._value, registrationDate: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Color
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={color}
                        onChange={(e) => onChange(_value => ({..._value, color: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Body Style
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={bodyStyle}
                        onChange={(e) => onChange(_value => ({..._value, bodyStyle: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Fuel Type
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={fuelType}
                        onChange={(e) => onChange(_value => ({..._value, fuelType: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Engine Capacity
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={engineCapacity}
                        onChange={(e) => onChange(_value => ({..._value, engineCapacity: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Transmission
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        value={transmission}
                        onChange={(e) => onChange(_value => ({..._value, transmission: e.target.value}))}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack direction="row" columnGap={2} sx={{py: 2, borderBottom: 1, borderBottomColor: "divider"}}>
                    <Typography variant="body1">
                        Forward Gears
                    </Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        fontWeight="bold"
                        InputProps={{ disableUnderline: true, sx: {fontWeight: 'bold'} }}
                        type="number"
                        onChange={(e) => onChange(_value => ({..._value, transmissionGears: e.target.value}))}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default VehicleData