import { Fragment, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTheme, Box, Drawer, Toolbar, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Collapse } from "@mui/material"
import { Close, DirectionsCar, ExpandLess, ExpandMore, Garage, Logout, Map, Person, Settings } from "@mui/icons-material"

import { useAccessToken, useVehicles } from "../../../context/AuthContext"

const makeStyles = (theme, width) => ({
    nav: {
        width,
        flexShrink: 0
    },
    sidebarDesktop: {
        display: {
            xs: 'none',
            lg: 'block'
        },
        '& .MuiPaper-root': {
            boxSizing: 'border-box',
            width
        }
    },
    sidebarMobile: {
        display: {
            xs: 'block',
            lg: 'none'
        },
        '& .MuiPaper-root': {
            boxSizing: 'border-box',
            width
        }
    },
    logo: {
        maxHeight: '40px'
    },
    sidebarCloseButton: {
        ml: 'auto'
    }
})

const SidebarContent = () => {

    const navigate = useNavigate()
    const pathname = useLocation().pathname 
    const [accessToken] = useAccessToken()
    const [vehicles] = useVehicles()

    const [collapsed, setCollapsed] = useState({ vehicles: false })

    return (
        <List sx={{p: 2}}>
            <ListItemButton 
                selected={pathname == '/monitor'}
                onClick={() => navigate('/monitor')}
            >
                <ListItemIcon>
                    <Map />
                </ListItemIcon>
                <ListItemText>
                    Monitor
                </ListItemText>
            </ListItemButton>
            <ListItemButton 
                selected={pathname.indexOf('/vehicles') != -1}
                onClick={() => setCollapsed(_collapsed => ({..._collapsed, vehicles: !_collapsed.vehicles}))}
                sx={{borderBottom: collapsed.vehicles ? 1 : 0, borderBottomColor: 'divider'}}
            >
                <ListItemIcon>
                    <Garage />
                </ListItemIcon>
                <ListItemText>
                    Vehicles
                </ListItemText>
                {collapsed.vehicles ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={collapsed.vehicles}>
                <List>
                    {vehicles?.map(vehicle => (
                        <ListItemButton 
                            key={vehicle._id}
                            onClick={() => navigate(`/vehicle/${vehicle._id}`)}
                        >
                            <ListItemIcon>
                                <DirectionsCar />
                            </ListItemIcon>
                            <ListItemText>
                                {vehicle.name}
                            </ListItemText>
                        </ListItemButton>
                    ))}
                    <ListItem>
                        <Button 
                            fullWidth 
                            variant="contained" 
                            size="medium"
                            onClick={() => navigate('/vehicle/add')}
                        >
                            Add Vehicle
                        </Button>
                    </ListItem>
                </List>
            </Collapse>
            <ListItemButton
                selected={pathname == '/settings'}
                onClick={() => navigate('/settings')}
            >
                <ListItemIcon>
                    <Settings />
                </ListItemIcon>
                <ListItemText>
                    Settings
                </ListItemText>
            </ListItemButton>
            <ListItemButton
                selected={pathname == '/account'}
                onClick={() => navigate('/account')}
            >
                <ListItemIcon>
                    <Person />
                </ListItemIcon>
                <ListItemText>
                    Account
                </ListItemText>
            </ListItemButton>
            <ListItemButton 
            selected={pathname == '/signOut'}
            onClick={() => navigate('/signOut')}
            sx={{mt: 'auto'}}
        >
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText>
                    Sign-out
                </ListItemText>
            </ListItemButton>
        </List>
    )
}

const Sidebar = ({width, open, onClose}) => {

    const theme = useTheme()
    const styles = makeStyles(theme, width)

    return (
        <Box component="nav" sx={styles.nav}>
            <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                ModalProps={{
                    keepMounted: true
                }}
                sx={styles.sidebarMobile}
            >
                <Toolbar>
                    <IconButton
                        onClick={onClose}
                        sx={styles.sidebarCloseButton}
                    >
                        <Close />
                    </IconButton>
                </Toolbar>

                <SidebarContent />
            </Drawer>

            <Drawer
                variant="permanent"
                anchor="left"
                sx={styles.sidebarDesktop}
            >
                <Toolbar>
                    <Box component="img" src={theme.logo} sx={styles.logo} />
                </Toolbar>
                <SidebarContent />
            </Drawer>
        </Box>
    )   
}

export default Sidebar