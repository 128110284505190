import { useEffect } from "react"
import { Box } from "@mui/material"

import { useAccessToken } from "../context/AuthContext"

const SignOutScreen = () => {

    const [, setAccessToken] = useAccessToken()

    useEffect(() => {
        setAccessToken(undefined)
    }, [])

    return (
        <Box component="div" />
    )
}

export default SignOutScreen