import {useState, useEffect} from 'react'

const getStoredValue = (key, defaultValue) => {
    const storedValue = JSON.parse(localStorage.getItem(key))
    if (storedValue != undefined)
        return storedValue

    if (defaultValue instanceof Function)
        return defaultValue()
    return defaultValue
}

const storeValue = (key, value) => {
    if (value == undefined) {
        localStorage.removeItem(key)
        return
    }

    localStorage.setItem(key, JSON.stringify(value))
}   

const useLocalStorage = (key, defaultValue) => {

    const [storedValue, setStoredValue] = useState(getStoredValue(key, defaultValue))

    useEffect(() => {
        storeValue(key, storedValue)
    }, [storedValue])

    return [storedValue, setStoredValue]
}

export default useLocalStorage