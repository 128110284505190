import { useState, Fragment, useEffect } from "react"
import { Box, Toolbar } from "@mui/material"

import Sidebar from "./components/Sidebar"
import Topbar from "./components/Topbar"
import { useLocation } from "react-router-dom"

const SIDEBAR_WIDTH = '300px'

const makeStyles = (theme, sidebarWidth) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: {
            xs: '100%',
            lg: `calc(100% - ${sidebarWidth})`
        },
        height: '100dvh',
        ml: {
            xs: 0,
            lg: sidebarWidth
        },
        p: 2,
    }
})

const DashboardLayout = ({children}) => {

    const location = useLocation()

    const styles = makeStyles(undefined, SIDEBAR_WIDTH)

    const [sidebarOpen, setSideBarOpen] = useState(false)

    useEffect(() => {
        setSideBarOpen(false)
    }, [location])

    return (
        <Fragment>
            <Topbar sidebarWidth={SIDEBAR_WIDTH} onSidebarOpen={() => setSideBarOpen(true)} />
            <Sidebar width={SIDEBAR_WIDTH} open={sidebarOpen} onClose={() => setSideBarOpen(false)} />
            <Box component="main" sx={styles.content}>
                <Toolbar />
                {children}
            </Box>
        </Fragment>
    )
}

export default DashboardLayout