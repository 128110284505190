import axios from 'axios'

const BASE_URL = 'https://api.sentrytrack.co.uk/auth'

export const signIn = (email, password) => axios.post(`${BASE_URL}/signIn`, {email, password})

export const signUp = (firstName, lastName, email, phone, password, confirmPassword) => {
    return axios.post(`${BASE_URL}/signUp`, {
        firstName,
        lastName,
        email, 
        phone,
        password,
        confirmPassword
    })
}