import { Fragment } from "react"
import { AppBar, Toolbar, IconButton, Box, useTheme } from "@mui/material"
import { Menu } from "@mui/icons-material"

const makeStyles = (theme, sidebarWidth) => ({
    topbar: {
        width: {
            xs: '100%',
            lg: `calc(100% - ${sidebarWidth})`
        },
        ml: sidebarWidth
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sidebarOpenButton: {
        display: {
            xs: 'flex',
            lg: 'none'
        }
    },
    logo: {
        maxHeight: '40px',
        display: {
            xs: 'block',
            lg: 'none'
        }
    }
})

const Topbar = ({sidebarWidth, onSidebarOpen}) => {

    const theme = useTheme()

    const styles = makeStyles(undefined, sidebarWidth)

    return (
        <AppBar
            position="fixed"
            sx={styles.topbar}
            elevation={0}

        >
            <Toolbar sx={styles.toolbar}>
                <Box component="img" src={theme.logo} sx={styles.logo} />
                <IconButton
                    onClick={onSidebarOpen}
                    sx={styles.sidebarOpenButton}
                >
                    <Menu />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default Topbar