import { Fragment } from "react"
import { Stack, Typography } from "@mui/material"

import DashboardLayout from "../../layouts/DashboardLayout"
import Map from "./components/Map"
import EventLog from "./components/EventLog"

const MonitorScreen = () => {

    return (
        <DashboardLayout>
            <Stack direction="column" rowGap={2} sx={{flex: 1}}>
                <Map />
            </Stack>
        </DashboardLayout>
    )
}

export default MonitorScreen