import { useLocation, useNavigate } from "react-router-dom"
import { Box, Drawer, IconButton, List, ListItemButton, ListItemText, Toolbar, useTheme } from "@mui/material"
import { Close } from "@mui/icons-material"

const makeStyles = theme => ({
    navmenu: {
        display: {
            xs: 'block',
            lg: 'none'
        },
        '& .MuiPaper-root': {
            boxSizing: 'border-box',
            flexShrink: 0
        }
    },
    topbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    logo: {
        maxHeight: '40px'
    }
})

const Navmenu = ({open, onClose}) => {

    const navigate = useNavigate()
    const path = useLocation().pathname
    const theme = useTheme()

    const styles = makeStyles()

    return (
        <Drawer
            variant="temporary"
            anchor="top"
            ModalProps={{
                keepMounted: true
            }}
            open={open}
            onClose={onClose}
            sx={styles.navmenu}
        >
            <Toolbar sx={styles.topbar}>
                <Box component="img" src={theme.logo} sx={styles.logo} />
                <IconButton 
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            </Toolbar>

            <List>
                <ListItemButton
                    selected={path == '/signIn'}
                    onClick={() => navigate('/signIn')}
                >
                    <ListItemText>
                        Sign-in
                    </ListItemText>
                </ListItemButton>
                <ListItemButton
                    selected={path == '/signUp'}
                    onClick={() => navigate('/signUp')}
                >
                    <ListItemText>
                        Sign-up
                    </ListItemText>
                </ListItemButton>
            </List>
        </Drawer>
    )
}

export default Navmenu