import { Routes, Route, Navigate } from 'react-router-dom'

import SignInScreen from '../screens/SignInScreen'
import SignUpScreen from '../screens/SignUpScreen'

const DefaultRoutes = () => {

    return (
        <Routes>
            <Route path="signUp" element={<SignUpScreen />} />
            <Route path="signIn" element={<SignInScreen />} />
            <Route path="/*" element={<Navigate to="/signIn" />} />
        </Routes>
    )
}

export default DefaultRoutes