import { useState, useRef, useEffect } from "react"
import { Box, Button, Container, Paper, TextField, Typography } from "@mui/material"
import { ArrowRightAlt } from "@mui/icons-material"

import { useAccessToken } from "../../context/AuthContext"
import { signIn } from "../../api/auth"
import PageLayout from "../../layouts/PageLayout"
import SecureTextField from "../../components/ScureTextField"

const SignInScreen = () => {

    const [, setAccessToken] = useAccessToken()

    const [errors, setErrors] = useState({})

    const emailRef = useRef()
    const passwordRef = useRef()

    const onSignInSubmit = (e) => {
        e.preventDefault(true)

        setErrors({})

        signIn(emailRef.current.value, passwordRef.current.value)
            .then(({data: {accessToken}}) => {
                setAccessToken(accessToken)
            })
            .catch(err => {
                setErrors(err?.response?.data?.errors || {})
            })
    }

    return (
        <PageLayout centered>
            <Container disableGutters maxWidth="sm">
                <Paper sx={{p: 2}}>
                    <Box component="div" sx={{mb: 4, mt: 2}}>
                        <Typography variant="h5" fontWeight="bold">
                            Sign-in
                        </Typography>
                        <Typography variant="body1">
                            Sign-in to your existing Sentry Track account
                        </Typography>
                    </Box>
                    <Box 
                        component="form" 
                        display="flex" 
                        flexDirection="column" 
                        rowGap={2}
                        onSubmit={onSignInSubmit}
                    >
                        <TextField 
                            fullWidth 
                            type="email"
                            inputRef={emailRef}
                            required
                            error={!!errors.email}
                            helperText={errors.email}
                            label="Email address"
                        />
                        <SecureTextField 
                            fullWidth 
                            inputRef={passwordRef}
                            required
                            error={!!errors.password}
                            helperText={errors.password}
                            label="Password"
                        />
                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            sx={{
                                width: 'fit-content',
                                ml: 'auto'
                            }}
                        >
                            Sign-in
                            <ArrowRightAlt sx={{ml: 1}} />
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </PageLayout>
    )
}

export default SignInScreen