import {createTheme} from '@mui/material'

import LogoLight from '../assets/Logo_Light.png'
import LogoDark from '../assets/Logo_Dark.png'

const Light = createTheme({
    logo: LogoLight,
    palette: {
        mode: 'light',
        primary: {
            main: '#D23D3D'
        },
        background: {
            default: '#F4F4F6'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: "#FFF",
                }
            }
        }
    }
})

const Dark = createTheme({
    logo: LogoDark,
    palette: {
        mode: 'dark',
        primary: {
            main: '#D23D3D'
        },
        background: {
            default: '#121212',
            paper: '#212121'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: "#212121",
                }
            }
        }
    }
})

export default {
    Light,
    Dark
}