import { useState, useRef } from "react"
import { Box, Button, Container, FormGroup, List, ListItem, Paper, TextField, Typography } from "@mui/material"
import { ArrowRightAlt } from "@mui/icons-material"

import { useAccessToken } from "../../context/AuthContext"
import { signUp } from "../../api/auth"
import PageLayout from "../../layouts/PageLayout"
import SecureTextField from "../../components/ScureTextField"

const SignUpScreen = () => {

    const [, setAccessToken] = useAccessToken()
    const [errors, setErrors] = useState({})

    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()
    const passwordRef = useRef()
    const confirmPasswordRef = useRef()

    const onSignUpSubmit = (e) => {
        e.preventDefault(true)

        setErrors({})

        signUp(
            firstNameRef.current.value,
            lastNameRef.current.value,
            emailRef.current.value,
            phoneRef.current.value,
            passwordRef.current.value,
            confirmPasswordRef.current.value
        ).then(({data: {accessToken}}) => setAccessToken(accessToken))
        .catch(err => {
            setErrors(err?.response?.data?.errors || {})
        })
    }

    return (
        <PageLayout centered>
            <Container disableGutters maxWidth="sm">
                <Paper sx={{p: 2}}>
                    <Box component="div" sx={{mb: 4, mt: 2}}>
                        <Typography variant="h5" fontWeight="bold">
                            Sign-up
                        </Typography>
                        <Typography variant="body1">
                            Create an account for Sentry Track
                        </Typography>
                    </Box>
                    <Box 
                        component="form" 
                        display="flex" 
                        flexDirection="column" 
                        rowGap={2}
                        onSubmit={onSignUpSubmit}
                    >
                        <FormGroup row>
                            <TextField 
                                sx={{flex: 1, mr: 2}}
                                inputRef={firstNameRef}
                                required
                                error={!!errors.name}
                                helperText={errors.firstName}
                                label="First name"
                            />
                            <TextField 
                                sx={{flex: 1}}
                                inputRef={lastNameRef}
                                required
                                error={!!errors.name}
                                helperText={errors.lastName}
                                label="Last name"
                            />
                        </FormGroup>
                        <TextField 
                            fullWidth 
                            type="email"
                            inputRef={emailRef}
                            error={!!errors.email}
                            helperText={errors.email}
                            label="Email address"
                        />
                        <TextField 
                            fullWidth 
                            inputRef={phoneRef}
                            required
                            error={!!errors.phone}
                            helperText={errors.phone}
                            label="Phone number"
                        />
                        <SecureTextField 
                            fullWidth 
                            inputRef={passwordRef}
                            required
                            error={!!errors.password}
                            helperText={errors.password}
                            label="Password"
                        />
                        <SecureTextField 
                            fullWidth 
                            inputRef={confirmPasswordRef}
                            required
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            label="Confirm password"
                        />
                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            sx={{
                                width: 'fit-content',
                                ml: 'auto'
                            }}
                        >
                            Sign-up
                            <ArrowRightAlt sx={{ml: 1}} />
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </PageLayout>
    )
}

export default SignUpScreen