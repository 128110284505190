import { useRef, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Box, Typography, TextField, Button, FormControl, FormLabel, Grid, Stack} from "@mui/material"

import { useAccessToken, useAccount, useVehicles } from "../../context/AuthContext"
import { createVehicle, getDataFromVrm } from "../../api/vehicle"
import DashboardLayout from "../../layouts/DashboardLayout"
import VehicleData from "../../features/VehicleData"
import { ArrowRightAlt } from "@mui/icons-material"

const makeStyles = theme => ({
    vrmPlate: {
        width: 'fit-content',
        fontWeight: 'bold',
        pl: 0,
        overflow: 'hidden',
        background: '#F4D148',
    },
    vrmPlateCountry: {
        py: 2,
        px: 0.5,
        mr: 1,
        color: '#F4D148',
        background: '#2A3D8A',
    }
})

const AddVehicleScreen = () => {

    const params = useParams()
    const navigate = useNavigate()
    const [accessToken, ] = useAccessToken()
    const [, fetchAccount] = useAccount()
    const [, fetchVehicles] = useVehicles()
    const styles = makeStyles()

    const [disabled, setDisabled] = useState(false)
    const [vehicle, setVehicleData] = useState({deviceId: params.deviceId})

    const vrmRef = useRef()

    const onVrmSearch = (disabled) => {
        if (disabled)
            return
        setDisabled(true)

        getDataFromVrm(accessToken, vrmRef.current.value)
            .then(({data: {vehicleData}}) => setVehicleData(_vehicleData => ({..._vehicleData, ...vehicleData, name: _vehicleData.name ? _vehicleData.name : vehicleData.vrm})))
            .catch((err) => console.log(err))
            .finally(() => setDisabled(false))
    }

    const onAddVehicle = (disabled) => {
        if (disabled)
            return
        setDisabled(true)

        createVehicle(accessToken, vehicle)
            .then(() => {
                fetchAccount()
                fetchVehicles()
                navigate('/monitor')
            })
            .catch((err) => console.log(err))
            .finally(() => setDisabled(false))
    }

    return (
        <DashboardLayout>   
            <Stack direction="column" rowGap={2}>
                <Typography variant="h5" fontWeight="bold">
                    Add Vehicle
                </Typography>

                <Box component="div" textAlign={{xs: "center", lg: "left"}}>
                <TextField 
                    InputProps={{
                        startAdornment: (
                            <Box component="div" sx={styles.vrmPlateCountry}>
                                <Typography variant="body1" fontWeight="bold">
                                    GB
                                </Typography>
                            </Box>
                        ),
                        endAdornment: (
                            <Button 
                                variant="text" 
                                color="inherit"
                                disabled={disabled}
                                onClick={() => onVrmSearch(disabled)}
                            >
                                Search
                            </Button>
                        ),
                        sx: styles.vrmPlate
                    }}
                    inputRef={vrmRef}
                />

                </Box>

                <VehicleData editable value={vehicle} onChange={setVehicleData} />

                <Box component="div" textAlign="right">
                    <Button
                        variant="contained"
                        size="large"
                        disabled={disabled}
                        onClick={() => onAddVehicle(disabled)}
                    >
                        Continue <ArrowRightAlt sx={{ml: 1}} />
                    </Button>
                </Box>
            </Stack>
        </DashboardLayout>
    )
}

export default AddVehicleScreen