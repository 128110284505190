import { Fragment } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { AppBar, Box, Toolbar, Tabs, Tab, IconButton, useTheme } from "@mui/material"
import { Menu } from "@mui/icons-material"

const makeStyles = theme => ({
    navbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    logo: {
        maxHeight: '40px'
    },
    links: {
        display: {
            xs: 'none', 
            lg: 'block'
        }
    },
    menuOpenButton: {
        display: {
            xs: 'block', 
            lg: 'none'
        }
    }
})

const Navbar = ({onMenuOpen}) => {

    const navigate = useNavigate()
    const path = useLocation().pathname
    const theme = useTheme()

    const styles = makeStyles()

    return (
        <AppBar
            position="fixed"
        >
            <Toolbar sx={styles.navbar}>
                <Box component="img" src={theme.logo} sx={styles.logo} />

                <Tabs value={path} sx={styles.links}>
                    <Tab value="/signIn" label="Sign-in" onClick={() => navigate('/signIn')} />
                    <Tab value="/signUp" label="Sign-up" onClick={() => navigate('/signUp')} />
                </Tabs>

                <IconButton 
                    sx={styles.menuOpenButton}
                    onClick={onMenuOpen}
                >
                    <Menu />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar