import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Paper } from "@mui/material"

import DashboardLayout from "../../layouts/DashboardLayout"
import { useVehicles } from "../../context/AuthContext"
import VehicleData from "../../features/VehicleData"

const VehicleScreen = () => {

    const params = useParams()
    const [vehicles] = useVehicles()

    const [vehicle, setVehicle] = useState({})

    useEffect(() => {
        setVehicle(vehicles?.find(vehicle => params.vehicleId == vehicle._id) || {})
    }, [vehicles])

    return (
        <DashboardLayout>
            <VehicleData value={vehicle} />
        </DashboardLayout>
    )
}

export default VehicleScreen