import { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SecureTextField = (props) => {

    const  [visible, setVisible] = useState(false)

    return (
        <TextField 
            {...props}
            type={visible ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <IconButton
                        onClick={() => setVisible(_visible => !_visible)}
                    >
                        {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                )
            }}
        />
    )
}

export default SecureTextField