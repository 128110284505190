
import DashboardLayout from "../../layouts/DashboardLayout"

const AccountScreen = () => {

    return (
        <DashboardLayout>
        </DashboardLayout>
    )
}

export default AccountScreen