import axios from 'axios'

const BASE_URL = 'https://api.sentrytrack.co.uk/vehicle'

export const getVehicles = (accessToken) => axios(`${BASE_URL}?now=${new Date().getTime()}`, {headers: {'Access-Token': accessToken}})

export const createVehicle = (accessToken, vehicle) => axios.post(`${BASE_URL}/create`, {vehicle}, {headers: {'Access-Token': accessToken}})

export const getDataFromVrm = (accessToken, vrm) => axios(`${BASE_URL}/data/vrm/${vrm}`, {headers: {'Access-Token': accessToken}})

export const getRecords = (accessToken, deviceId, fromDate, untilDate) => axios.post(`${BASE_URL}/records/${deviceId}`, {fromDate, untilDate}, {headers: {'Access-Token': accessToken}})

export const getLatestVehicleRecord = (accessToken, deviceId) => axios.get(`${BASE_URL}/record/latest/${deviceId}`, {headers: {'Access-Token': accessToken}})

export const getVehicleLocation = accessToken => axios(`${BASE_URL}/location?now=${new Date().getTime()}`, {headers: {'Access-Token': accessToken}})