import { Fragment, useEffect, useState} from "react"
import { useLocation } from "react-router-dom"
import { Box, Toolbar } from "@mui/material"

import Navbar from "./components/Navbar"
import Navmenu from "./components/Navmenu"

const makeStyles = theme => ({
    mainWrapper: centered => ({
        display: centered ? 'flex' : 'block',
        flexDirection: 'column',
        flex: 1,
        minHeight: centered ? '100dvh' : '0px',
    }),
    main: centered => ({
        display: centered ? 'flex' : 'block',
        flexDirection: 'column',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2
    })
})

const PageLayout = ({centered, children}) => {

    const location = useLocation()

    const styles = makeStyles()

    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
        setMenuOpen(false)
    }, [location])

    return (
        <Fragment>
            <Navbar onMenuOpen={() => setMenuOpen(true)} />
            <Navmenu open={menuOpen} onClose={() => setMenuOpen(false)} />
            <Box component="div" sx={styles.mainWrapper(centered)}>
                <Toolbar />
                <Box component="main" sx={styles.main(centered)}>
                    {children}
                </Box>
            </Box>
        </Fragment>
    )
}

export default PageLayout