import { BrowserRouter as Router } from "react-router-dom"
import { ThemeProvider, CssBaseline } from "@mui/material"

import { useAccount } from "./context/AuthContext"
import Theme from './assets/Theme'
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes"
import DefaultRoutes from "./routes/DefaultRoutes"

const App = () => {

    const [account] = useAccount()

    return (
        <Router>
            <ThemeProvider theme={Theme.Light}>
                <CssBaseline />
                {account ? <AuthenticatedRoutes /> : <DefaultRoutes />}
            </ThemeProvider>
        </Router>
    )   
}

export default App