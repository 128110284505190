import {createContext, useContext, useEffect} from 'react'

import useLocalStorage from '../hooks/useLocalStorage'
import { getAccount } from '../api/account'
import { getVehicles } from '../api/vehicle'

const AccessTokenContext = createContext()
const AccountContext = createContext()
const VehiclesContext = createContext()

export const useAccessToken = () => {
    return useContext(AccessTokenContext)
}

export const useAccount = () => {
    return useContext(AccountContext)
}

export const useVehicles = () => {
    return useContext(VehiclesContext)
}

const AuthProvider = ({children}) => {

    const [accessToken, setAccessToken] = useLocalStorage('accessToken')
    const [account, setAccount] = useLocalStorage('account')
    const [vehicles, setVehicles] = useLocalStorage('vehicles', [])

    useEffect(() => {
        if (!accessToken) {
            setAccount(undefined)
            return
        }

        fetchAccount()
        fetchVehicles()
    }, [accessToken])

    const fetchAccount = () => {
        getAccount(accessToken)
            .then(({data: {account}}) => setAccount(account))
            .catch(err => {
                if (err?.response?.status == 401) 
                    setAccessToken(undefined)
            })
    }

    const fetchVehicles = () => {
        getVehicles(accessToken)    
            .then(({data: {vehicles}}) => {
                console.log(vehicles)
                setVehicles(vehicles)
            })
            .catch(err => console.log(err))
    }

    return (
        <AccessTokenContext.Provider value={[accessToken, setAccessToken]}>
            <AccountContext.Provider value={[account, fetchAccount]}>
                <VehiclesContext.Provider value={[vehicles, fetchVehicles]}>
                    {children}
                </VehiclesContext.Provider>
            </AccountContext.Provider>
        </AccessTokenContext.Provider>
    )
}

export default AuthProvider